define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-chooser", ["exports", "@ember/object", "@ember-decorators/component", "discourse-common/lib/helpers", "select-kit/components/multi-select"], function (_exports, _object, _component, _helpers, _multiSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HouseAdsChooser = dt7948.c(class HouseAdsChooser extends _multiSelect.default {
    filterable = true;
    filterPlaceholder = "admin.adplugin.house_ads.filter_placeholder";
    tokenSeparator = "|";
    allowCreate = false;
    allowAny = false;
    settingValue = "";
    valueAttribute = null;
    nameProperty = null;
    get value() {
      return this.settingValue.toString().split(this.tokenSeparator).filter(Boolean);
    }

    // TODO: kept for legacy, remove when Discourse is 2.5
    static #_ = (() => dt7948.n(this.prototype, "value", [(0, _object.computed)("settingValue")]))();
    mutateValues(values) {
      this.set("settingValue", values.join(this.tokenSeparator));
    }
    computeValues() {
      return this.settingValue.split(this.tokenSeparator).filter(Boolean);
    }
    get content() {
      return (0, _helpers.makeArray)(this.choices);
    }
    static #_2 = (() => dt7948.n(this.prototype, "content", [(0, _object.computed)("choices")]))();
    onChange(value) {
      const settingValue = (0, _helpers.makeArray)(value).join(this.tokenSeparator);
      this.onChange?.(settingValue);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
  }, [(0, _component.classNames)("house-ads-chooser")]);
  var _default = _exports.default = HouseAdsChooser;
});